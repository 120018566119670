.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;

  & > ul,
  & > ol {
    text-align: left;
    width: fit-content;
    /* center */
    margin-left: auto;
    margin-right: auto;
  }

  & > p {
    /* center */
    margin-left: auto;
    margin-right: auto;
  }
}

.alignRight {
  text-align: right;
  & > ul,
  & > ol {
    text-align: left;
    width: fit-content;
    /* push to right */
    margin-left: auto;
  }

  & > p {
    /* push to right */
    margin-left: auto;
  }
}

.sectionRefoundWork{
  text-align: center;
}

.sectionRefoundWorkIcon{
  width: 100px;
  margin: auto;
}